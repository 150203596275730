import postMessage from 'Modules/PostMessage';

interface Params {
    topLevelDomain: string;
    data: string;
    service: string;
}

const TEST_IFRAME_ID = 'xhh_test_iframe';

const sendDebugDataToTestHH = ({ data, service, topLevelDomain }: Params): void => {
    const testIframe = document.getElementById(TEST_IFRAME_ID) as HTMLIFrameElement;

    if (testIframe?.contentWindow) {
        postMessage.trigger({
            target: testIframe.contentWindow,
            message: {
                name: 'debug_data',
                data: { payload: data, service },
            },
        });

        return;
    }

    const src = location.host.includes('pyn.ru') ? `https://test.${location.host}` : `https://test.${topLevelDomain}`;

    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.id = TEST_IFRAME_ID;
    iframe.style.display = 'none';

    iframe.onload = () => {
        if (!iframe.contentWindow) {
            return;
        }

        postMessage.trigger({
            target: iframe.contentWindow,
            message: {
                name: 'debug_data',
                data: { payload: data, service },
            },
        });
    };

    document.body.appendChild(iframe);
};

export { sendDebugDataToTestHH };
