import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        '/shards/notifications': {
            queryParams: {
                notificationCode: string;
            };
            body: null;
            response: void;
        };
    }
}

const putNotificationToBell = (): void => {
    void fetcher.post('/shards/notifications', null, {
        params: {
            notificationCode: 'RESUME_KINGDOM_OF_VACANCY',
        },
    });
};

export default putNotificationToBell;
