import { DataProvider, DataProviderItem, RemoteDataProviderResponse } from 'bloko/blocks/suggest/types';

export const removeDuplicates = (items: DataProviderItem[]): DataProviderItem[] =>
    items.filter(
        (item, index) =>
            !items.slice(0, index).some((previousItem) => previousItem.text.toLowerCase() === item.text.toLowerCase())
    );

const enrichResumeSearchSuggest = (
    query: string,
    remoteDataProvider: DataProvider,
    staticDataProvider: DataProvider,
    lastSearches: DataProviderItem[]
): Promise<RemoteDataProviderResponse> => {
    if (query.length) {
        return remoteDataProvider(query).then((data) => {
            const items = Array.isArray(data.items) ? data.items : [];
            const filteredLastSearches = lastSearches.filter(
                (item) => item.text !== '' && item.text.toLowerCase().startsWith(query.toLowerCase())
            );
            return {
                items: removeDuplicates([...filteredLastSearches, ...items]),
            };
        });
    }
    return staticDataProvider(query);
};

export { enrichResumeSearchSuggest };
