import { useEffect, useState, RefObject, useCallback } from 'react';
import classnames from 'classnames';

import { GridColumn, GridRow } from '@hh.ru/magritte-ui';
import { MenuLayer } from 'bloko/blocks/drop/Menu';
import BlokoLink, { LinkKind, LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Tabs, { Tab } from 'bloko/blocks/tabs';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import AreaSwitcherTopLevelTitle from 'src/components/AreaSwitcher/AreaSwitcherTopLevelTitle';
import CitiesList from 'src/components/AreaSwitcher/CitiesList';
import Footer from 'src/components/AreaSwitcher/Footer';
import { AreaSwitcherScreens, AlphabetData } from 'src/components/AreaSwitcher/areaSwitcherTypes';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { fetcher } from 'src/utils/fetcher';

const SUBDOMAINS_BY_LETTER_URL = '/shards/area_switcher/subdomains_by_letter';

declare global {
    interface FetcherGetApi {
        [SUBDOMAINS_BY_LETTER_URL]: {
            response: AlphabetData;
            queryParams: {
                areaId?: number;
                backUrl?: string;
                letter: string;
            };
        };
    }
}

interface SubdomainsByLetterProps {
    isDropdown?: boolean;
    searchInputRef: RefObject<HTMLInputElement>;
    setCurrentScreen: (val: AreaSwitcherScreens) => void;
    areaId?: number;
    currentDomainFirstLetter?: string;
}

const TrlKeys = {
    chooseOtherCountry: 'area.switcher.chooseOtherCountry',
    chooseOtherCity: 'area.switcher.chooseOtherCity',
};

const SubdomainsByLetter: TranslatedComponent<SubdomainsByLetterProps> = ({
    trls,
    isDropdown,
    searchInputRef,
    setCurrentScreen,
    areaId,
    currentDomainFirstLetter,
}) => {
    const [alphabetData, setAlphabetData] = useState<AlphabetData | null>(null);
    const [currentLetter, setCurrentLetter] = useState(currentDomainFirstLetter || '');
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotification();

    useEffect(() => {
        const controller = new AbortController();

        const getTopLevelDomains = async () => {
            let domains;

            setIsLoading(true);

            try {
                domains = await fetcher.get(SUBDOMAINS_BY_LETTER_URL, {
                    signal: controller.signal,
                    params: {
                        backUrl: window.location.href,
                        letter: currentLetter,
                        areaId,
                    },
                });
            } catch (error) {
                defaultRequestErrorHandler(error, addNotification);
                return;
            } finally {
                setIsLoading(false);
            }

            setAlphabetData(domains);
        };

        void getTopLevelDomains();

        return () => controller.abort();
    }, [addNotification, areaId, currentLetter]);

    const isActiveButton = useCallback(
        (letter: string, index: number) => {
            if (!currentLetter) {
                return index === 0;
            }

            return currentLetter === letter;
        },
        [currentLetter]
    );

    const renderLoading = (
        <>
            {isLoading && (
                <div
                    className={classnames({
                        'supernova-dropdown-static-option': isDropdown,
                    })}
                >
                    <Loading scale={LoadingScale.Medium} />
                </div>
            )}
        </>
    );

    return (
        <GridColumn xs={4} s={8} m={12} l={12}>
            <VSpacing base={6} />
            {!alphabetData && renderLoading}
            {!!alphabetData && (
                <>
                    <GridColumn xs={4} s={8} m={12} l={12}>
                        <div className="area-switcher-header">
                            <AreaSwitcherTopLevelTitle domain={alphabetData.currentTopLevelDomain} />
                            <div className="area-switcher-select-country">
                                <BlokoLink
                                    kind={LinkKind.Secondary}
                                    appearance={LinkAppearance.Pseudo}
                                    onClick={() => setCurrentScreen(AreaSwitcherScreens.TopLevelDomains)}
                                >
                                    {alphabetData.canChoseAnotherDomain
                                        ? trls[TrlKeys.chooseOtherCountry]
                                        : trls[TrlKeys.chooseOtherCity]}
                                </BlokoLink>
                            </div>
                        </div>
                        {alphabetData.shouldShowAlphabet && (
                            <Tabs
                                menuParams={{
                                    layer: MenuLayer.OverlayContent,
                                }}
                            >
                                {alphabetData.firstLetters.map((letter, index) => (
                                    <Tab
                                        id={letter}
                                        key={letter}
                                        active={isActiveButton(letter, index)}
                                        onClick={() => setCurrentLetter(letter)}
                                    >
                                        {letter}
                                    </Tab>
                                ))}
                            </Tabs>
                        )}
                    </GridColumn>
                    <VSpacing base={6} />
                    {renderLoading}
                    {!isLoading && (
                        <GridRow>
                            {alphabetData.subdomainsByLetter.map((subdomainsByLetter) => (
                                <GridColumn xs={4} s={4} m={4} l={3} key={subdomainsByLetter[0]?.areaId}>
                                    <CitiesList domain={subdomainsByLetter} />
                                </GridColumn>
                            ))}
                        </GridRow>
                    )}
                </>
            )}
            <Footer searchInputRef={searchInputRef} />
        </GridColumn>
    );
};

export default translation(SubdomainsByLetter);
