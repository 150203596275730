import { Primitive } from 'type-fest';

import urlParser from 'bloko/common/urlParser';

const DEFAULT_TEXT_VALUE = 'пустое_значение';

interface SearchTypeParams extends SearchType {
    name: string | null;
    path: RegExp | string;
    paramsExisting?: string[] | [];
    removeArgs?: string[] | [];
    updateArgs: Record<string, string>[];
}

class SearchType {
    constructor(
        public name: string | null,
        public path: RegExp | string,
        public updateArgs: Record<string, string>[] = []
    ) {
        this.name = name;
        this.path = new RegExp(path);
        this.updateArgs = updateArgs;
    }
}

class SearchTypeWithParamsExisting extends SearchType {
    constructor(
        name: string | null,
        path: RegExp | string,
        public paramsExisting: string[] = [],
        updateArgs: Record<string, string>[] = []
    ) {
        super(name, path, updateArgs);
        this.paramsExisting = paramsExisting;
    }
}

class SearchTypeWithArgsToRemove extends SearchType {
    constructor(
        name: string | null,
        path: RegExp | string,
        public removeArgs: string[] = [],
        updateArgs: Record<string, string>[] = []
    ) {
        super(name, path, updateArgs);
        this.removeArgs = removeArgs;
    }
}

const ResumeSimple = new SearchType('resume_simple', '^/search/resume$');
const ResumeCatalog = new SearchType('resume_catalog_list', '^/resumes');
const ResumeCatalogRegion = new SearchType('resume_catalog_region', '^/region/resumes');
const VacancySimple = new SearchType('vacancy_simple', '^/search/vacancy$');
const VacancyMap = new SearchType('vacancy_map', '^/search/vacancy/map');
const VacancyCatalog = new SearchType(
    'vacancy_catalog_list',
    '^/vacancies(?!/podrabotka|[/a-zA-Z_-]*/podrabotka)/?([a-zA-Z_-]*)/?[a-zA-Z_-]*$'
);
const VacancyCatalogRegion = new SearchType('vacancy_catalog_region', '^/region/vacancies');
const VacancyCatalogMetro = new SearchType('vacancy_catalog_metro', '^/metro');
const VacancyCatalogPodrabotka = new SearchType(
    'vacancy_catalog_podrabotka',
    '^/vacancies/?([a-zA-Z_-]*)?/podrabotka$'
);
const VacancyProbation = new SearchType('vacancy_catalog_stazhirovki', '^/stazhirovki([/a-zA-Z_-]*)?$');
const VacancySezonnayaRabota = new SearchType('catalog_sezonnaya_rabota', '/vacancies/sezonnaya_rabota', [
    { text: DEFAULT_TEXT_VALUE },
]);
const VacancyCatalogProfessions = new SearchType('vacancy_catalog_professions', '/catalog/[a-zA-Z_-]*');

const ResumeSimilarByVacancy = new SearchTypeWithParamsExisting('resume_similar_by_vacancy', '/search/resume', [
    'vacancy_id',
]);
const ResumeSimilarByResume = new SearchTypeWithParamsExisting('resume_similar_by_resume', '/search/resume', [
    'resume',
]);
const VacancyByEmployer = new SearchTypeWithParamsExisting('vacancy_by_employer', '/search/vacancy', ['employer_id']);
const VacancySimilarByResume = new SearchTypeWithParamsExisting('vacancy_similar_by_resume', '/search/vacancy', [
    'resume',
]);

const EmployersList = new SearchTypeWithArgsToRemove('employers_list', '/employers_list', ['query']);
const ResumeAdvanced = new SearchTypeWithArgsToRemove(null, '/search/resume/advanced', ['text']);
const EmployerResumeFolders = new SearchTypeWithArgsToRemove(null, '/employer/resumefolders/search', ['text']);
const VacancyAdvanced = new SearchTypeWithArgsToRemove(null, '/search/vacancy/advanced', ['text']);

const SEARCH_TYPES = [
    EmployersList,
    ResumeSimple,
    ResumeAdvanced,
    VacancySimple,
    ResumeSimilarByVacancy,
    ResumeSimilarByResume,
    ResumeCatalog,
    ResumeCatalogRegion,
    EmployerResumeFolders,
    VacancyAdvanced,
    VacancyByEmployer,
    VacancySimilarByResume,
    VacancyMap,
    VacancyCatalog,
    VacancyCatalogRegion,
    VacancyCatalogMetro,
    VacancyCatalogPodrabotka,
    VacancyCatalogProfessions,
    VacancyProbation,
    VacancySezonnayaRabota,
];

const getTypesWithParamsExisting = (searchTypesList: SearchTypeParams[]): SearchTypeParams[] => {
    return searchTypesList.filter((type) => type instanceof SearchTypeWithParamsExisting);
};

const getTypesWithoutParams = (searchTypesList: SearchTypeParams[]): SearchTypeParams[] => {
    return searchTypesList.filter((type: SearchTypeParams) => type instanceof SearchType);
};

class SearchPageLocationForGA {
    searchTypes: SearchTypeParams[];
    updateArgs: Record<string, never>;
    removeArgs: [];
    filteredSearchTypes: Record<string, SearchTypeParams[]>;

    /**
     * @param search_types list of SearchType objects.
     * @param update_args arguments to add/update in result url.
     * @param remove_args arguments to remove from result url.
     * @param filtered_search_types SearchType objects filtered by url and params.
     */

    constructor() {
        this.searchTypes = SEARCH_TYPES;
        this.updateArgs = {};
        this.removeArgs = [];
        this.filteredSearchTypes = {};
    }

    _filterSearchTypes(urlPath: string): Record<string, SearchTypeParams[]> {
        const filteredSearchTypesByPath: SearchTypeParams[] = this.searchTypes.filter((type: SearchTypeParams) => {
            const match = new RegExp(type.path, 'g').exec(urlPath);
            if (match?.[1]) {
                type.updateArgs.push({ text: match[1] });
            }
            return match;
        });

        const filteredSearchTypesByParams = {
            typesWithoutParams: getTypesWithoutParams(filteredSearchTypesByPath),
            typesWithParamsExisting: getTypesWithParamsExisting(filteredSearchTypesByPath),
        };
        return filteredSearchTypesByParams;
    }

    _getSearchType(urlQueryDict: Record<string, Primitive[] | Primitive>): SearchTypeParams | undefined {
        let currentSearchType;
        if (this.filteredSearchTypes.typesWithParamsExisting) {
            currentSearchType = this.filteredSearchTypes.typesWithParamsExisting.find((searchType) => {
                return searchType?.paramsExisting?.find((value: string) => {
                    return Object.keys(urlQueryDict).find((queryValue: string) => {
                        return queryValue === value;
                    });
                });
            });
        }
        if (!currentSearchType && this.filteredSearchTypes.typesWithoutParams) {
            currentSearchType = this.filteredSearchTypes.typesWithoutParams[0];
        }
        return currentSearchType;
    }

    prepareUrl(pathname: string, search: string): string {
        this.filteredSearchTypes = this._filterSearchTypes(pathname);

        const searchParsed = urlParser(`${pathname}${search}`);
        const searchParams = searchParsed.params;
        const searchType = this._getSearchType(searchParams);

        if (searchType) {
            if (searchParams.text) {
                const text = searchParams.text.toString();
                searchParams.text = text.length ? text.toLowerCase() : DEFAULT_TEXT_VALUE;
            } else if (searchParams.query) {
                const text = searchParams.query.toString();
                searchParams.text = text.length ? text.toLowerCase() : DEFAULT_TEXT_VALUE;
            } else {
                searchParams.text = [DEFAULT_TEXT_VALUE];
            }

            if (searchType.updateArgs) {
                searchType.updateArgs.forEach((args) => {
                    Object.entries(args).map(([key, value]) => (searchParams[key] = value.toString()));
                });
            }

            if (searchType.name) {
                searchParams.st = searchType.name;
            }

            if (searchType.removeArgs) {
                searchType.removeArgs.forEach((arg) => delete searchParams[arg]);
            }
        }

        return searchParsed.href;
    }
}

export { SearchPageLocationForGA };
