import Storage from 'bloko/common/storage/LocalStorageWrapper';

import { sendDebugDataToTestHH } from 'src/utils/sendDebugDataToTestHH';

let wasCollected = false;

export const collectDebugData = (topLevelDomain: string | null): void => {
    if (wasCollected || !topLevelDomain) {
        return;
    }

    const chatikDebugData = Storage.getItem('chatik_debug_last_opened_data');

    if (!chatikDebugData) {
        return;
    }

    sendDebugDataToTestHH({ data: chatikDebugData, service: 'chatik', topLevelDomain });
    wasCollected = true;
};
