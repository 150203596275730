import Debug from 'HHC/Debug';
import { fetcher } from 'src/utils/fetcher';

const NOTIFICATION_ACKNOWLEDGED_URL = '/applicant/reviews/acknowledged';

declare global {
    interface FetcherPostApi {
        [NOTIFICATION_ACKNOWLEDGED_URL]: {
            body: void;
            queryParams: void;
            response: void;
        };
    }
}

export default (): void => {
    fetcher.post(NOTIFICATION_ACKNOWLEDGED_URL).catch((error) => Debug.log('out error', error));
};
