let initStart;

if (!process.env.SSR) {
    initStart = performance.now();
}
const { hydrateRoot, createRoot } = require('react-dom/client');
const { Provider } = require('react-redux');
const { ConnectedRouter } = require('connected-react-router');
const statsSender = require('HHC/Performance/StatsSender').default;
const Debug = require('HHC/Debug').default;
const getOrCreateStore = require('src/app/createStore').default;
const { getHistory } = require('src/utils/history');
const { domainsWhitelist } = require('src/utils/constants/domainsWhitelist');
const App = require('src/app/App').default;
const { setLuxSentryBeforeSend } = require('HHC/LuxInit');
const Components = require('HHC/Components').default;
const { urlHelper } = require('@hh.ru/redux-spa-middleware');
const isGoogleTranslateEnv = require('Modules/googleTranslateChecker').default;
const sendToAnalytics = require('HHC/Performance/SendToAnalytics').default;

// анонимная функция, потому что нужен return при ошибке инициализации
(() => {
    const reactRootNode = document.getElementById('HH-React-Root');
    const reactErrorNode = document.getElementById('HH-React-Error');

    if (!reactRootNode) {
        console.error(new Error("React root node doesn't exist"));
        if (reactErrorNode !== null) {
            reactErrorNode.style.display = 'block';
        }
        return;
    }

    if (reactRootNode.dataset.ssrStatus === 'fail') {
        reactRootNode.dataset.qa = 'lux-server-render-failed';
    }

    let store;
    try {
        store = getOrCreateStore();
    } catch (error) {
        if (error.code !== 'REACT_INITIAL_STATE_ERROR') {
            throw error;
        }
        console.error(error);
        reactRootNode.style.display = 'none';
        if (reactErrorNode !== null) {
            reactErrorNode.style.display = 'block';
        }
        return;
    }

    urlHelper.setDomainWhitelist(domainsWhitelist);

    setLuxSentryBeforeSend((event) => {
        if (
            Array.isArray(event.fingerprint) &&
            event.fingerprint.some((value) => value.includes('The node to be removed is not a child of this node.'))
        ) {
            const pageName = event.extra.state.request?.luxPageName;
            event.fingerprint = [pageName];
        }
    });

    const RootComponent = ({ renderCallback } = { renderCallback: null }) => (
        <Provider store={store}>
            <ConnectedRouter history={getHistory()}>
                <App renderCallback={renderCallback} />
            </ConnectedRouter>
        </Provider>
    );

    let renderStart;
    let initEnd;

    if (!process.env.SSR) {
        initEnd = performance.now();
    }

    const sendRenderTime = () => {
        if (!process.env.SSR) {
            const luxInitTime = initEnd - initStart;
            const luxFirstRenderTime = performance.now() - renderStart;
            const luxInitTimeFromNavStart = performance.now();
            statsSender.sendMetrics([
                {
                    metricName: 'lux-init-time',
                    valueInMs: luxInitTime,
                },
                {
                    metricName: 'lux-first-render-time',
                    valueInMs: luxFirstRenderTime,
                },
                {
                    metricName: 'lux-init-time-from-nav-start',
                    valueInMs: performance.now(),
                },
            ]);

            sendToAnalytics('lux-init-time', luxInitTime);
            sendToAnalytics('lux-first-render-time', luxFirstRenderTime);
            sendToAnalytics('lux-init-time-from-nav-start', luxInitTimeFromNavStart);
        }
    };

    import('@hh.ru/loadable-component')
        .then(({ preloadReady }) => preloadReady())
        .then(() => {
            if (!process.env.SSR) {
                renderStart = performance.now();
            }

            if (!reactRootNode.dataset.ssrFailedStatus) {
                try {
                    hydrateRoot(reactRootNode, <RootComponent renderCallback={sendRenderTime} />, {
                        onRecoverableError: (error) => {
                            if (!isGoogleTranslateEnv()) {
                                Debug.log('error out', error);
                            }
                        },
                    });
                } catch (error) {
                    Debug.log('error out', error);
                    createRoot(reactRootNode).render(<RootComponent renderCallback={sendRenderTime} />);
                }
            } else {
                createRoot(reactRootNode).render(<RootComponent renderCallback={sendRenderTime} />);
            }

            window.addEventListener('load', Components.buildDeferredComponents, { once: true });

            return null;
        })
        .catch((error) => {
            Debug.log('error out', error);
        });
})();
