import { MouseEventHandler, useEffect, FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { useElementShown } from '@hh.ru/analytics-js';
import topPromoBannerClosed from '@hh.ru/analytics-js-events/build/xhh/common/top_promo_banner/top_promo_banner_closed';
import topPromoBannerShown from '@hh.ru/analytics-js-events/build/xhh/common/top_promo_banner/top_promo_banner_shown';
import topPromoBannerVisited from '@hh.ru/analytics-js-events/build/xhh/common/top_promo_banner/top_promo_banner_visited';
import { CrossOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import HSpacing from 'bloko/blocks/hSpacing';
import { H3Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import NoIndex from 'src/components/NoIndex';
import { useSelector } from 'src/hooks/useSelector';
import { hideTopPromoBanner } from 'src/models/topPromoBanner';
import { fetcher } from 'src/utils/fetcher';

import { useIsShowTopBanner } from 'src/components/TopPromoBanner/useIsShowTopBanner';

import styles from './top-promo-banner.less';

const BANNER_SHOWN_URL = '/top_promo_banner/banner_shown';
const BANNER_CLOSED_URL = '/top_promo_banner/close';
const BANNER_VISITED_URL = '/top_promo_banner/visit';

declare global {
    interface FetcherPostApi {
        [BANNER_SHOWN_URL]: {
            queryParams: void;
            body: {
                banner_name: string;
            };
            response: void;
        };
        [BANNER_VISITED_URL]: {
            queryParams: void;
            body: {
                banner_name: string;
            };
            response: void;
        };
        [BANNER_CLOSED_URL]: {
            queryParams: void;
            body: {
                banner_name: string;
            };
            response: void;
        };
    }
}

const SEO_QUERIES = {
    hhtmFromLabel: 'top_banner',
};

const TopPromoBanner: FC = () => {
    const isShowBanner = useIsShowTopBanner();
    const dispatch = useDispatch();
    const userType = useSelector(({ userType }) => userType);
    const { isFirstShow, link, translations, name } = useSelector(({ topPromoBanner }) => topPromoBanner);
    const showBannerRef = useElementShown(topPromoBannerShown, {
        banner_name: name,

        is_first_shown: isFirstShow,
    });
    const isMobile = useBreakpoint(Breakpoint.L) === Breakpoint.XS;

    useEffect(() => {
        if (!isShowBanner) {
            return;
        }

        fetcher.post(BANNER_SHOWN_URL, { banner_name: name }).catch(console.error);
    }, [isFirstShow, isShowBanner, userType, name]);

    const handleClose: MouseEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();

        topPromoBannerClosed({ banner_name: name });

        fetcher.post(BANNER_CLOSED_URL, { banner_name: name }).catch(console.error);
        dispatch(hideTopPromoBanner());
    };

    const handleVisit = () => {
        topPromoBannerVisited({ banner_name: name });

        fetcher.post(BANNER_VISITED_URL, { banner_name: name }).catch(console.error);
        dispatch(hideTopPromoBanner());
    };

    const renderContent = (content: ReactElement) => {
        if (isMobile) {
            return (
                <div className={styles.banner} ref={showBannerRef}>
                    <div className={styles.container}>{content}</div>
                </div>
            );
        }

        return (
            <SPALink className={styles.container} to={link} target="_blank" additionalQueryParams={SEO_QUERIES}>
                <div className={styles.banner} onClick={handleVisit} ref={showBannerRef}>
                    {content}
                </div>
            </SPALink>
        );
    };

    if (!isShowBanner) {
        return null;
    }

    return (
        <NoIndex>
            {renderContent(
                <ColumnsWrapper>
                    <Column xs="4" s="8" m="12" l="16">
                        <div className={styles.wrapper}>
                            <div className={styles.contentDesktop}>
                                {!!translations.desktop.description && (
                                    <H3Section>{translations.desktop.description}</H3Section>
                                )}
                                {!!translations.desktop.description && !!translations.desktop.link && (
                                    <HSpacing base={6} />
                                )}
                                {!!translations.desktop.link && (
                                    <Button kind={ButtonKind.Inversed} scale={ButtonScale.Small}>
                                        {translations.desktop.link}
                                    </Button>
                                )}
                            </div>
                            <div className={styles.contentMobile}>
                                {!!translations.mobile.description && (
                                    <H3Section>{translations.mobile.description}</H3Section>
                                )}
                                {!!translations.mobile.desctiption && !!translations.mobile.link && (
                                    <VSpacing base={2} />
                                )}
                                {!!translations.mobile.link && (
                                    <>
                                        <VSpacing base={4} />
                                        <Button
                                            Element={SPALink}
                                            to={link}
                                            onClick={handleVisit}
                                            kind={ButtonKind.Inversed}
                                            scale={ButtonScale.Small}
                                        >
                                            {translations.mobile.link}
                                        </Button>
                                    </>
                                )}
                            </div>
                            <HSpacing base={6} />
                            <div className={styles.close} onClick={handleClose}>
                                <BlokoIconReplaceContainer>
                                    <CrossOutlinedSize16 initialColor="contrast" />
                                </BlokoIconReplaceContainer>
                            </div>
                        </div>
                    </Column>
                </ColumnsWrapper>
            )}
        </NoIndex>
    );
};

export default TopPromoBanner;
