import { useState } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import BlokoLink from 'bloko/blocks/link';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { MarkAsViewedEvent } from 'Modules/Notices';
import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPutApi {
        '/shards/user_agreements/adv_agreement': {
            body: void;
            queryParams: { source: string; accepted: boolean };
            response: void;
        };
    }
}

const TrlKeys = {
    text: 'advertisementAgreement.notification.text',
    linkOne: 'advertisementAgreement.notification.text.link.1',
    linkTwo: 'advertisementAgreement.notification.text.link.2',
    accept: 'advertisementAgreement.notification.accept',
    close: 'advertisementAgreement.notification.close',
};

const Features = {
    enableNotification: 'enable_advertisement_agreement_notification',
};

const AdvertisementAgreement: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const { addNotification } = useNotification();
    const [disabled, setDisabled] = useState(false);
    const isNotificationEnabled = useSelector(({ features }) => features[Features.enableNotification] as boolean);
    if (!isNotificationEnabled) {
        return null;
    }

    const makeRequest = (isAccepted: boolean) => {
        setDisabled(true);
        void fetcher
            .put('/shards/user_agreements/adv_agreement', undefined, {
                params: { source: 'web_notification', accepted: isAccepted },
            })
            .catch((error) => {
                defaultRequestErrorHandler(error, addNotification);
            })
            .finally(() => {
                setDisabled(false);
                onClose();
            });
    };

    const onAccept = () => {
        makeRequest(true);
    };

    const onDecline = () => {
        makeRequest(false);
    };

    return (
        <SynchronizedNotification
            notification={notification}
            markAsViewedEvent={MarkAsViewedEvent.None}
            onClose={onDecline}
        >
            <p>
                {formatToReactComponent(trls[TrlKeys.text], {
                    '{0}': (
                        <BlokoLink disableVisited Element={SPALink} to="/article/30901" target="_blank">
                            {trls[TrlKeys.linkOne]}
                        </BlokoLink>
                    ),
                    '{1}': (
                        <BlokoLink disableVisited Element={SPALink} to="/article/30901#clause1" target="_blank">
                            {trls[TrlKeys.linkTwo]}
                        </BlokoLink>
                    ),
                })}
            </p>
            <NotificationFooter>
                <Button scale={ButtonScale.Small} onClick={onAccept} disabled={disabled}>
                    {trls[TrlKeys.accept]}
                </Button>
                <FormSpacer />
                <Button scale={ButtonScale.Small} onClick={onDecline} disabled={disabled}>
                    {trls[TrlKeys.close]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(AdvertisementAgreement);
