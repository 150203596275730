import { TopLevelSite } from 'src/models/topLevelSite';

const domainsWhitelist = [
    TopLevelSite.RU,
    TopLevelSite.UZ,
    TopLevelSite.GE,
    TopLevelSite.KG,
    TopLevelSite.KZ,
    TopLevelSite.AZ,
    TopLevelSite.BY,
    TopLevelSite.JTB,
    TopLevelSite.ZP,
    'rabota.by',
    'hh.kz',
    'hh1.az',
    'talantix.ru',
    'hrdigital.ru',
    'hrbrand.ru',
    'zarplata.ru',
    'hrpremia.by',
];

export { domainsWhitelist };
