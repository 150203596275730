import { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios, { Canceler } from 'axios';

import {
    ModalStep,
    FetchOffersFunction,
} from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/types';
import { PossibleJobOffers, setApplicantPossibleJobOffers } from 'src/models/applicant/jobSearchStatus';
import { fetcher } from 'src/utils/fetcher';

type UseFetchPossibleJobOffersHook = () => {
    fetchOffers: FetchOffersFunction;
    cancelFetch: () => void;
};

declare global {
    interface FetcherGetApi {
        '/shards/applicant/negotiations/possible_job_offers': {
            queryParams: unknown;
            response: {
                possibleJobOffers?: PossibleJobOffers;
            };
        };
    }
}

const useFetchPossibleJobOffers: UseFetchPossibleJobOffersHook = () => {
    const dispatch = useDispatch();
    const cancelFetchRef = useRef<Canceler | null>(null);

    const cancelFetch = useCallback(() => {
        cancelFetchRef.current?.();
    }, []);

    const fetchOffers = useCallback<FetchOffersFunction>(
        async ({ setModalStep }) => {
            dispatch(setApplicantPossibleJobOffers([]));

            cancelFetchRef.current?.();
            const cancelToken = new axios.CancelToken((cancel: Canceler) => {
                cancelFetchRef.current = cancel;
            });

            let data = null;
            try {
                data = await fetcher.get('/shards/applicant/negotiations/possible_job_offers', {
                    params: {},
                    cancelToken,
                });
            } catch (error) {
                console.error(error);
            }

            if (!data?.possibleJobOffers || data.possibleJobOffers.length === 0) {
                setModalStep(ModalStep.ChooseReasonStep);
                return;
            }

            setModalStep(ModalStep.ChooseOfferStep);
            dispatch(setApplicantPossibleJobOffers(data.possibleJobOffers));
        },
        [dispatch]
    );

    return { fetchOffers, cancelFetch };
};

export default useFetchPossibleJobOffers;
