import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Push, Replace } from '@hh.ru/redux-spa-middleware';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import ModalHelper from 'bloko/common/modalHelper';
import urlParser from 'bloko/common/urlParser';

import Debug from 'HHC/Debug';
import fetchEmployerVacancies from 'src/api/employerVacancySearch/fetchEmployerVacancies';
import { fetchEmployerVacancyCount } from 'src/api/employerVacancySearch/fetchEmployerVacancyCount';
import { AppStore } from 'src/app/store';
import networkError from 'src/components/Notifications/NetworkError';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import fetchMapVacancies from 'src/components/Search/Common/Filters/api/fetchMapVacancies';
import getFetchResume from 'src/components/Search/Common/Filters/api/fetchResume';
import { fetchResumeSearchDraftClusters } from 'src/components/Search/Common/Filters/api/fetchResumeSearchDraftClusters';
import getFetchVacancies from 'src/components/Search/Common/Filters/api/fetchVacancies';
import getFetchVacancyCounts from 'src/components/Search/Common/Filters/api/fetchVacancyCounts';
import getSearchParams from 'src/components/Search/Common/Filters/utils/getSearchParams';
import { setSearchClusterTipData, setSearchClusterTipShow } from 'src/models/search/common/clustersTip';
import { SearchPage, SearchType } from 'src/types/search/common';
import { CriteriaKey } from 'src/types/search/common/criteria';
import { fetcher } from 'src/utils/fetcher';

import { adjustSavedArea } from 'src/components/Search/Common/Filters/actions/sendFilterForm/adjustSavedArea';

const searchLoadingAction = makeSetStoreField('searchLoading');
const searchPreferenceAction = makeSetStoreField('searchPreference');
const cancelFetchBannersAction = makeSetStoreField('cancelFetchBanners');

let tipTimeout: NodeJS.Timeout;

const isXsOrS = () => {
    const breakpoint = getBreakpoint();
    return [Breakpoint.XS, Breakpoint.S].includes(breakpoint);
};

export enum SendFilterFormMode {
    ApplyFilters = 'applyFilters',
    ApplyFiltersFromDraft = 'applyFiltersFromDraft',
    UpdateCounts = 'updateCounts',
    UpdateFiltersInDraft = 'updateFiltersInDraft',
}

const SEARCH_DRAFT_MODS = [SendFilterFormMode.UpdateFiltersInDraft, SendFilterFormMode.ApplyFiltersFromDraft];

export interface SendFilterFormArgs {
    mode?: SendFilterFormMode;
    abortSignal?: AbortSignal;
    isSearchSessionIdSaved?: boolean;
    showClustersTip?: boolean;
}

export type SendFilterForm = (
    args: SendFilterFormArgs,
    addNotification: AddNotification,
    replace: Replace,
    push: Push
) => (dispatch: Dispatch, getState: () => AppStore) => void;

const sendFilterForm: SendFilterForm =
    (
        { mode = SendFilterFormMode.ApplyFilters, abortSignal, isSearchSessionIdSaved = false, showClustersTip = true },
        addNotification,
        replace,
        push
    ) =>
    (dispatch, getState) => {
        const {
            criteriaCurrencyCode,
            criteriaTextUpdated,
            employerVacancySearch,
            currencies,
            enableVacancySnippets,
            novaSorts,
            resumeSearchResult,
            router,
            searchCatalogRedirect,
            searchClusters,
            searchDraftClusters,
            searchSettings,
            searchCounts,
            searchSessionId,
            vacancySearchResult,
            isAdjustDetectedRegionResumeExpEnabled,
            lastUpdatedSearchCluster,
            employerInfo,
            employerVacancyTemplateFilter,
            hasChameleon,
            isExpNewEmploymentFilters,
        } = getState();
        const { actionPath, type: searchType, page: searchPage } = searchSettings;

        const isEmployerVacancySearch = searchPage === SearchPage.EmployerView;

        const searchParams = getSearchParams({
            dispatch,
            criteriaCurrencyCode,
            criteriaTextUpdated: isEmployerVacancySearch
                ? employerVacancySearch.criteria?.[CriteriaKey.Text] || ''
                : criteriaTextUpdated,
            currencies,
            enableVacancySnippets,
            router,
            novaSorts,
            resumeSearchResult,
            searchCatalogRedirect,
            searchClusters: SEARCH_DRAFT_MODS.includes(mode) ? searchDraftClusters : searchClusters,
            searchType,
            searchPage,
            vacancySearchResult,
            employerInfo,
            employerVacancySearch,
            isAdjustDetectedRegionResumeExpEnabled,
            employerVacancyTemplateFilter,
            hasChameleon,
            isSearchSessionIdSaved,
            searchSessionId,
            isExpNewEmploymentFilters,
        });

        adjustSavedArea(
            lastUpdatedSearchCluster,
            searchType,
            isAdjustDetectedRegionResumeExpEnabled,
            searchParams,
            dispatch
        );
        const query = urlParser.stringify(searchParams as never);

        const sharedFetchArgs = { dispatch, query, abortSignal };

        // map vacancy search
        if (searchPage === SearchPage.VacancySearchMap) {
            fetchMapVacancies(sharedFetchArgs)().catch((error) => {
                Debug.log('out error', error, {
                    query,
                });
            });
            return;
        }

        if (mode === SendFilterFormMode.UpdateCounts) {
            if (isEmployerVacancySearch) {
                fetchEmployerVacancyCount({
                    ...sharedFetchArgs,
                    value: searchCounts.value,
                    addNotification,
                }).catch(console.error);
            } else if (searchType === SearchType.Vacancy) {
                const fetchCountsRequest = getFetchVacancyCounts({
                    ...sharedFetchArgs,
                    value: searchCounts.value,
                });
                fetchCountsRequest().catch((error) => {
                    if (fetcher.isCancel(error)) {
                        return;
                    }
                    addNotification(networkError);
                });
            }
            return;
        }

        if (mode === SendFilterFormMode.UpdateFiltersInDraft) {
            if (searchType === SearchType.Resume) {
                void fetchResumeSearchDraftClusters(sharedFetchArgs);
            }
            return;
        }

        if (isEmployerVacancySearch) {
            dispatch(
                fetchEmployerVacancies({
                    ...sharedFetchArgs,
                    addNotification,
                    replace,
                    isMobile: isXsOrS(),
                })
            ).catch(console.error);
            return;
        }

        // catalog redirects (with debounce)
        if (searchCatalogRedirect) {
            push(`${actionPath}?${query}`, { cancelScrollCorrection: true });
            return;
        }

        // request for search data's
        const fetchResponse =
            searchType === SearchType.Vacancy
                ? getFetchVacancies(sharedFetchArgs, push)
                : getFetchResume(sharedFetchArgs, push);

        // loader and tip
        dispatch([searchLoadingAction(true), setSearchClusterTipShow(false), cancelFetchBannersAction(true)]);

        if (tipTimeout) {
            clearTimeout(tipTimeout);
        }

        // after update search result
        fetchResponse()
            .then(({ totalResults, redirect }) => {
                if (redirect) {
                    push(redirect, { cancelScrollCorrection: true });
                    return;
                }
                dispatch([cancelFetchBannersAction(false), searchLoadingAction(false)]);

                // close xs/s form
                if (isXsOrS()) {
                    dispatch(searchPreferenceAction({ isShown: false }));
                    return;
                }

                if (mode === SendFilterFormMode.ApplyFilters) {
                    setTimeout(
                        () =>
                            dispatch(
                                setSearchClusterTipData({
                                    show: showClustersTip,
                                    totalResults,
                                    filter: lastUpdatedSearchCluster,
                                })
                            ),
                        // show result tip on next tick
                        60
                    );
                }
                tipTimeout = setTimeout(() => {
                    dispatch(setSearchClusterTipShow(false));
                }, 2000);
            })
            .catch((error) => {
                if (fetcher.isCancel(error)) {
                    return;
                }
                Debug.log('out error', error, { query });
                dispatch(searchLoadingAction(false));
                addNotification(networkError);
            })
            .finally(() => {
                if (searchType === SearchType.Resume) {
                    ModalHelper.enableScroll();
                }
            });
    };

export default sendFilterForm;
