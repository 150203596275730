import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';

import { putCheckedExperiment, getCheckedExperiments } from 'src/utils/checkedExperiments';
import { CONTROL_GROUP, EXPERIMENT_GROUP, NOT_IN_EXPERIMENT_GROUP } from 'src/utils/constants/experiments';

import { useSelector } from 'src/hooks/useSelector';

interface SendExperimentCheckAnalyticsParams {
    experimentName: string;
    experimentGroup: string;
}
export const sendExperimentCheckAnalytics = ({
    experimentName,
    experimentGroup,
}: SendExperimentCheckAnalyticsParams): void => {
    const experimentChecked = getCheckedExperiments();

    if (!experimentChecked.includes(experimentName)) {
        if (!process.env.SSR) {
            Analytics.sendHHEvent('user_in_experiment_check', {
                source: 'xhh-js',
                [experimentGroup]: experimentName,
            });
        }
        putCheckedExperiment(experimentName);
    }
};

export function useExperimentGroupCheckFunction(sendCheck = true): (experimentName: string) => string {
    const exps = useSelector(({ experiments }) => experiments);
    return useCallback(
        (experimentName) => {
            const experimentGroup = exps.enabled[experimentName] || NOT_IN_EXPERIMENT_GROUP;
            if (sendCheck) {
                sendExperimentCheckAnalytics({
                    experimentName,
                    experimentGroup,
                });
            }
            return experimentGroup;
        },
        [exps, sendCheck]
    );
}

// Вызов useExperiment в коде приводит к тому, что на /analytics отправляется запрос с event: user_in_experiment_check.
// В некоторых экспериментах подсчет итоговой статистики удобно производить по таким запросам.
// Когда этот запрос дошёл до базы, мы считаем, что пользователь мог увидеть экспериментальные изменения.
// Однако иногда пользователь в принципе не мог видеть экспериментальные изменения, потому что, например, находился на
// другом шаге во флоу компонента (скажем, эксперимент на заблокированное резюме, а у него не заблокированное) и т.д.
// И так как хук useExperiment нельзя вызвать по условию, добавлены параметры callHook и sendCheck
// callHook при значении false вернет из хука false и чек в /analytics не отошлется, иначе
// проверит вхождение пользователя в эксперимент и отправит чек на /analytics
// sendCheck влияет только на отправку чека в /analytics, при этом не влияя
// на определение самой экспериментальной группы пользователя
export default function useExperimentCheckFunction(
    callHook = true,
    sendCheck = true
): (experimentName: string) => boolean {
    const getExperimentGroup = useExperimentGroupCheckFunction(sendCheck);

    return useCallback(
        (experimentName) => {
            if (!callHook) {
                return false;
            }
            return getExperimentGroup(experimentName) === EXPERIMENT_GROUP;
        },
        [getExperimentGroup, callHook]
    );
}

export function useExperimentControlCheckFunction(
    callHook = true,
    sendCheck = true
): (experimentNames: string[]) => boolean {
    const getExperimentGroup = useExperimentGroupCheckFunction(sendCheck);
    return useCallback(
        (experimentNames = []) => {
            if (!callHook) {
                return false;
            }
            return experimentNames.some((experimentName) => {
                return getExperimentGroup(experimentName) === CONTROL_GROUP;
            });
        },
        [callHook, getExperimentGroup]
    );
}
