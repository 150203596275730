import { useState } from 'react';

import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import ImportantNotifications from 'src/components/ImportantNotifications';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { fetcher } from 'src/utils/fetcher';

const TrlKeys = {
    content: 'emailVerificationInfo.notVerified',
    send: 'emailVerificationInfo.send',
    success: 'emailVerificationInfo.success',
    check: 'emailVerificationInfo.check',
    canGet: 'emailVerificationInfo.cantGet',
    error: 'emailVerificationInfo.error',
    tryAgain: 'emailVerificationInfo.tryAgain',
};

const EmailVerification: TranslatedComponent = ({ trls }) => {
    const { needVerification, email, mailURL } = useSelector(({ emailVerification }) => emailVerification);
    const [isSuccess, setSuccess] = useState(false);
    const [hasError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    if (!needVerification) {
        return null;
    }

    const sendData = () => {
        setLoading(true);

        fetcher
            .post('/shards/email_verification_send')
            .then(() => {
                setSuccess(true);
                setError(false);
            })
            .catch(() => {
                setSuccess(false);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ImportantNotifications>
            {!isSuccess && !hasError && (
                <>
                    {format(trls[TrlKeys.content], { '{0}': email || '' })}{' '}
                    {!isLoading && (
                        <Link appearance={LinkAppearance.Pseudo} kind={LinkKind.Inversed} onClick={sendData}>
                            {trls[TrlKeys.send]}
                        </Link>
                    )}
                    {isLoading && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                </>
            )}
            {isSuccess && (
                <div>
                    {trls[TrlKeys.success]}
                    <Link href={mailURL} target="_blank" kind={LinkKind.Inversed}>
                        {trls[TrlKeys.check]}
                    </Link>{' '}
                    <Link href="/article/email_faq" target="_blank" kind={LinkKind.Inversed}>
                        {trls[TrlKeys.canGet]}
                    </Link>
                    ?
                </div>
            )}
            {hasError && (
                <div>
                    {trls[TrlKeys.error]}{' '}
                    {!isLoading && (
                        <Link appearance={LinkAppearance.Pseudo} kind={LinkKind.Inversed} onClick={sendData}>
                            {trls[TrlKeys.tryAgain]}
                        </Link>
                    )}
                    {isLoading && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                </div>
            )}
        </ImportantNotifications>
    );
};

export default translation(EmailVerification);
