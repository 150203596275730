import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { fetcher } from 'src/utils/fetcher';

const searchCountsAction = makeSetStoreField('searchCounts');

const VACANCY_COUNTS_URL = '/shards/vacancy/counts';

declare global {
    interface FetcherGetApi {
        [VACANCY_COUNTS_URL]: {
            response: { counts: number };
            queryParams: undefined;
        };
    }
}

interface Params {
    dispatch: Dispatch;
    query: string;
    value: number;
    abortSignal?: AbortSignal;
}

type FetchVacancyCounts = () => Promise<number>;

const getFetchVacancyCounts =
    ({ dispatch, query, value, abortSignal }: Params): FetchVacancyCounts =>
    async () => {
        dispatch(searchCountsAction({ isLoad: true, value }));
        let response;
        try {
            response = await fetcher.get(`${VACANCY_COUNTS_URL}?${query}` as typeof VACANCY_COUNTS_URL, {
                params: undefined,
                signal: abortSignal,
            });
        } catch (error) {
            dispatch(searchCountsAction({ isLoad: false, value: 0 }));
            return Promise.reject(error as Error);
        }

        // update search data
        const { counts } = response;
        dispatch(searchCountsAction({ isLoad: false, value: response.counts }));
        return counts;
    };

export default getFetchVacancyCounts;
