import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useSelector } from 'src/hooks/useSelector';
import { EmployerDivision } from 'src/models/employerDivisions';
import { EmployerImplants } from 'src/models/employerImplants';
import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherGetApi {
        '/employer/implants/get_implants': {
            queryParams: void;
            response: EmployerImplants;
        };
        '/shards/employer/divisions': {
            queryParams: void;
            response: {
                employerCurrentDivision: EmployerDivision;
                employerDivisions: Record<number, EmployerDivision>;
            };
        };
    }
}

const employerCurrentDivisionAction = makeSetStoreField('employerCurrentDivision');
const employerDivisionsAction = makeSetStoreField('employerDivisions');
const employerImplantsAction = makeSetStoreField('employerImplants');

export const useHandleDivisionsData = (): void => {
    const dispatch = useDispatch();

    const hasDivisions = useSelector((state) => state.employerHasDivisions);
    const divisions = useSelector((store) => store.employerDivisions);
    const isLoadDivisions = useRef(true);

    useEffect(() => {
        const abortController = new AbortController();
        if (hasDivisions && Object.keys(divisions).length === 0 && isLoadDivisions.current) {
            fetcher.get('/shards/employer/divisions', { signal: abortController.signal }).then((data) => {
                isLoadDivisions.current = false;
                if (data.employerCurrentDivision.divisionName) {
                    dispatch(employerCurrentDivisionAction(data.employerCurrentDivision));
                }
                dispatch(employerDivisionsAction(data.employerDivisions));
            }, console.error);
        }
        return () => {
            abortController.abort();
        };
    }, [dispatch, divisions, hasDivisions, isLoadDivisions]);
};

export const useHandleImplantsData = (): void => {
    const dispatch = useDispatch();

    const hasImplants = useSelector((state) => state.session.hasExtraAccounts);
    const implants = useSelector((store) => store.employerImplants);
    const isLoadImplants = useRef(true);

    useEffect(() => {
        const abortController = new AbortController();

        if (hasImplants && implants === null && isLoadImplants.current) {
            fetcher.get('/employer/implants/get_implants', { signal: abortController.signal }).then((data) => {
                isLoadImplants.current = false;
                dispatch(employerImplantsAction(data));
            }, console.error);
        }
        return () => {
            abortController.abort();
        };
    }, [dispatch, hasImplants, implants, isLoadImplants]);
};
