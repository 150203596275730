import { useState, useMemo, useCallback, ReactNode, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Tip, { TipPlacement } from 'bloko/blocks/drop/Tip';
import InputText from 'bloko/blocks/inputText';
import { SuggestLayer } from 'bloko/blocks/suggest';
import SuggestPickerItems from 'bloko/blocks/suggest/SuggestPickerItems';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import createStaticDataProvider from 'bloko/blocks/suggest/createStaticDataProvider';
import { DataProviderItem } from 'bloko/blocks/suggest/types';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { dispatchNotLuxDataUpdate } from 'Modules/notLuxData';
import AutoInviteContext from 'src/components/AutoInviteModal/common/AutoInviteContext';
import SmartSuggest from 'src/components/SupernovaSearch/SmartSuggest';
import useSendSuggestAnalytics from 'src/components/SupernovaSearch/SmartSuggest/useSendAnalytics';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { SearchQueries } from 'src/models/search/resume/queries';
import { removeDuplicates, enrichResumeSearchSuggest } from 'src/utils/resumeSearch/enrichResumeSearchSuggest';

interface SearchQueryProps {
    query: SearchQueries;
    setFromSuggest: (value: boolean) => void;
}

const TrlKeys = {
    searchQueryPlaceholder: 'supernova.search.placeholder.resumeSearch',
    tooLongQuery: 'query.length.moreThanMax',
    apply: 'Apply',
};

const WILDCARD = '%QUERY%';
const RESUME_REMOTE = `/vacancysuggest/?q=${WILDCARD}`;
const MAX_QUERY_LENGTH = 400;

const updateLastSearchQueryText = makeSetStoreField('lastUpdatedSearchQueryText');

const SearchQuery: TranslatedComponent<SearchQueryProps> = ({ trls, query, setFromSuggest }) => {
    const criteriaTextUpdated = useSelector(({ criteriaTextUpdated }) => criteriaTextUpdated);
    const dispatch = useDispatch();
    const { sendSuggestShownAnalytics, sendSuggestItemClickAnalytics } = useSendSuggestAnalytics();

    // set criteriaTextUpdated for catalog
    const [text, setText] = useState(
        query.text[0] || (Array.isArray(criteriaTextUpdated) ? criteriaTextUpdated[0] : criteriaTextUpdated) || ''
    );
    const [tipVisible, showTip, hideTip] = useOnOffState(false);
    const lastSearches = useSelector((state) => state.employerLastSearches);

    const remoteDataProvider = useMemo(() => createRemoteDataProvider(RESUME_REMOTE, WILDCARD), []);
    const staticDataProvider = useMemo(() => createStaticDataProvider(removeDuplicates(lastSearches)), [lastSearches]);
    const autoInviteHandlerFormChange = useContext(AutoInviteContext);
    const activatorRef = useRef(null);

    const dataProvider = useCallback(
        (query: string) => enrichResumeSearchSuggest(query, remoteDataProvider, staticDataProvider, lastSearches),
        [lastSearches, remoteDataProvider, staticDataProvider]
    );

    const updateText = (text: string) => {
        setText(text);
        dispatch(updateLastSearchQueryText(text));
        dispatchNotLuxDataUpdate({ inputValue: text });
    };

    useEffect(() => {
        autoInviteHandlerFormChange?.();
    }, [autoInviteHandlerFormChange, text]);

    const handleInputChange = (value: string) => {
        if (value.length < MAX_QUERY_LENGTH) {
            updateText(value);
        } else {
            showTip();
            setTimeout(hideTip, 2000);
        }
    };

    const onDesktopSuggestShown = useCallback(
        (node: HTMLDivElement) => {
            if (node) {
                sendSuggestShownAnalytics();
            }
        },
        [sendSuggestShownAnalytics]
    );

    const handleSmartSuggestChange = (item: DataProviderItem) => {
        if (item) {
            sendSuggestItemClickAnalytics(item);
            updateText(item.text);
            setFromSuggest(true);
        }
    };

    return (
        <Tip
            show={tipVisible}
            onExternalClose={hideTip}
            placement={TipPlacement.Top}
            render={() => trls[TrlKeys.tooLongQuery]}
            activatorRef={activatorRef}
        >
            <SmartSuggest
                dataProvider={dataProvider}
                defaultSearch={text}
                selectOnBlur={false}
                suggestStartInputLength={lastSearches.length > 0 ? 0 : undefined}
                onChange={handleSmartSuggestChange}
                onSubmit={handleInputChange as (v: string) => void}
                submitButtonTitle={trls[TrlKeys.apply]}
                renderItems={(items, renderItem: (item: DataProviderItem, index: number) => ReactNode) => (
                    <div ref={onDesktopSuggestShown}>
                        <SuggestPickerItems>{items.map((item, index) => renderItem(item, index))}</SuggestPickerItems>
                    </div>
                )}
                inputValue={text}
                onMobileSuggestShown={sendSuggestShownAnalytics}
                layer={SuggestLayer.AboveOverlayContent}
            >
                <InputText
                    name="text"
                    value={text}
                    onChange={handleInputChange}
                    placeholder={trls[TrlKeys.searchQueryPlaceholder]}
                    data-qa="resumes-search-wizard-item-keyword"
                    autoComplete="off"
                    ref={activatorRef}
                />
            </SmartSuggest>
        </Tip>
    );
};

export default translation(SearchQuery);
