import numberFormatter from 'bloko/common/numberFormatter';

import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const formatNumber = (value: string | number): string => {
    return numberFormatter.format(String(value), {
        groupSeparator: NON_BREAKING_SPACE,
    });
};

export { formatNumber };
