import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import Debug from 'HHC/Debug';
import { OrderedClusterMap } from 'src/models/search/common/clustersOrder';
import { setSearchDraftClustersOrder } from 'src/models/search/common/clustersOrder/draft';
import { setSearchDraftCount, setSearchDraftLoading } from 'src/models/search/common/draft';
import { SearchClusterMap } from 'src/types/search/common/clusters';
import { fetcher } from 'src/utils/fetcher';

const searchDraftClustersAction = makeSetStoreField('searchDraftClusters');

const URL = '/shards/search/resume/clusters';

interface Response {
    count: number;
    clusters: SearchClusterMap;
    clustersOrder: OrderedClusterMap;
}

declare global {
    interface FetcherGetApi {
        [URL]: {
            queryParams: unknown;
            response: Response;
        };
    }
}

interface Params {
    dispatch: Dispatch;
    query: string;
    abortSignal?: AbortSignal;
}

export const fetchResumeSearchDraftClusters = async ({ dispatch, query, abortSignal }: Params) => {
    dispatch(setSearchDraftLoading(true));

    let response;
    try {
        response = await fetcher.get<typeof URL>(`${URL}?${query}`, { signal: abortSignal });
    } catch (error) {
        if (fetcher.isCancel(error)) {
            return;
        }
        Debug.log('out error', error, { query });
        return;
    } finally {
        dispatch(setSearchDraftLoading(false));
    }

    dispatch([
        setSearchDraftCount(response.count),
        searchDraftClustersAction(response.clusters),
        setSearchDraftClustersOrder(response.clustersOrder),
    ]);
};
