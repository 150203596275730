import { FormValues } from 'src/models/applicantSubscriptions';
import { fetcher } from 'src/utils/fetcher';

const APPLICANT_SUBSCRIBE_URL = '/subscribe';

interface Payload {
    subscriptions: FormValues;
}

declare global {
    interface FetcherPostApi {
        [APPLICANT_SUBSCRIBE_URL]: {
            queryParams: void;
            body: Payload['subscriptions'];
            response: {
                redirectUrl?: string;
            };
        };
    }
}

const saveApplicantSubscriptions = async ({ subscriptions }: Payload) =>
    await fetcher.post(APPLICANT_SUBSCRIBE_URL, subscriptions);

export default saveApplicantSubscriptions;
